import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Link from "@/components/link";

const Blog = ({data}) => {
  console.log(data)
 const posts = data.allMarkdownRemark.nodes;

  return (
    <MenuContextProvider>
      <Layout PageTitle="Blog Page">
        <HeaderOne />
        <PageHeader title="Blog" name="Blog" />
        <section className="blog_share_area section_padding blog-page">
          <Container>
            <Row>
              {posts.map((post, index) => (
                <Col lg={4} md={6} sm={12} key={{ index }}>
                  <Link href={"/blog-details/" + post.frontmatter.slug} key={post.id} >
                    <div className="blog_share_box">
                      <div className="bl_share_img">
                      </div>
                      <div className="blog_share_details">
                        <h1>
                          <Link className="blog_share_title">{post.frontmatter.title}</Link>
                        </h1>
                        <p>{post.frontmatter.description}</p>
                      </div>
                    </div>
                  </Link>
                </Col>
                
              ))}
            </Row>
          </Container>
        </section>
          <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Blog;

export const query = graphql`
  query ProjectsPage2 {
    allMarkdownRemark {
      nodes {
        frontmatter {
          slug          
          title
          description
        }
        id
      }
    }
  }`;